.kiddpro__cta {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  padding: 2rem;
  margin: 4rem;
  border-radius: 1rem;
  background: var(--color-footer);

  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

}
.kiddpro__cta-content {
  display: flex;
  flex-direction: column;

  text-align: left;
  color: #fff;
}

.kiddpro__cta-content p {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 30px;
  font-weight: 500;
}

.kiddpro__cta-content h3 {
  font-family: var(--font-family);
  font-size: 24px;
  line-height: 45px;
  font-weight: 800;
}

.kiddpro__cta-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

.kiddpro__cta-button button {
  background: var(--gradient-bar);
  color: #282828;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 1rem;
  border-radius: 2rem;

  border: none;
  outline: none;
  cursor: pointer;
  min-width: 150px;
}

@media screen and (max-width: 650px) {
  .kiddpro__cta {
    flex-direction: column;
  }

  .kiddpro__cta-button {
    margin: 2rem 00;
  }
}

@media screen and (max-width: 550px) {
  .kiddpro__cta {
    margin: 4rem 2rem;
  }
  .kiddpro__cta-content h3 {
    font-size: 18px;
    line-height: 32px;
  }
  .kiddpro__cta-button button {
    font-size: 14px;
    line-height: 28px;
  }
}
