@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

:root {
  --font-family: "Manrope", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #00F9FF 1.84%, #00FF93 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #00F9FF -13.86%, #00FF93 99.55%);

  --color-bg: #282828;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #ffffff;
  --color-subtext: #ff8a71;
}
