
.kiddpro__contact{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: none;
  text-align: center;
  width: 100%;
  margin-bottom: 5rem;
  cursor: pointer;
  }


/* Add a background color and some padding around the form */
.kiddpro__contact-content {
  padding: 1rem 0;
  margin: 0 1rem;
  border-radius: 4px;
  font-family: var(--font-family);
  font-weight: 400px;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);

  margin-top: 1.5rem;
  width: 50%;
  margin: 0 1rem;
}

.kiddpro__contact-content h1{
  line-height: 40px;
}

/* Style the submit button with a specific background color etc */
.kiddpro__contact-content_submit{
  width: 250px;
  background: var(--gradient-bar);
  font-weight: 400px;
  font-size: 20px;
  font-family: var(--font-family);
  color: #282828;
  padding: 12px 20px;
  margin: auto;
  margin-top: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


/* When moving the mouse over the submit button, add a darker green color */
.kiddpro__contact-content_submit button:hover {
  background-color: #45a049;
}

.kiddpro__contact input {
  width: 100%;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: var(white);
  padding: 0 1rem;
  outline: none;
  color: #282828;
}

label {
  display: flex;
  margin-top: 1rem;
}


@media screen and (max-width: 450px) {
  .kiddpro__contact-content{
    width: 100%;
  }
   .kiddpro__contact-content h1{
    font-size: 28px;
    line-height: 28px;
    margin-top: 1rem;
  }


  label {
    justify-content:center;
    align-items: center;
  }
}
