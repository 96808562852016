.flex__row {
  flex-direction: row;
}

.kiddpro__faq-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.kiddpro__faq-header {
  font-size: 50px;
  line-height: 60px;
  font-weight: 800;
  font-family: var(--font-family);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.kiddpro__faq-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  
}

.kiddpro__faq {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  padding-left: 5rem;
  padding-right: 5rem;

  margin: 4rem;
  border-radius: 1rem;
  background: var(--color-footer);

  /* ff 3.6+ */
  background: -moz-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* opera 11.10+ */
  background: -o-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* ie 10+ */
  background: -ms-radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  /* global 92%+ browsers support */
  background: radial-gradient(
    circle at 30% -100%,
    #042c54 25%,
    rgba(4, 44, 84, 1) 85%,
    rgba(27, 120, 222, 1) 100%
  );

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.kiddpro__faq-content {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  text-align: left;
  color: #fff;
}

.kiddpro__faq-content h2 {
  font-family: var(--font-family);
  font-size: 34px;
  line-height: 55px;
  font-weight: 800;
}

.kiddpro__faq-content h3 {
  font-family: var(--font-family);
  font-size: 24px;
  line-height: 45px;
  font-weight: 800;
}

.kiddpro__faq-content p {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 30px;
  font-weight: 500;
}
